.bt-help-support-card {
  .btn-txt {
    display: inline-block;
    width: calc(100% - 37px);
  }
  .link-btn-wrp {
    display: inline-block;
    width: calc(100% - 35px);
    vertical-align: middle;
  }
  .bt-card-title-wrp {
    padding: 15px 0 2px;
    border-bottom: 1px solid #eee;
    .bt-card-title {
      font-size: 36px;
      font-family: "BTLight";
      line-height: 1.25;
    }
  }

  .bt-help-links-wrp {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    width: 100%;
    background-color: #ffffff;
    padding:25px 24px 25px;
    margin-top: 9px;
    .bt-help-links-col-wrp{
      display: flex;
    }
    .faq-help-support-wrp {
        display: block;
        margin: 38px 0 0;
        border-top: 1px solid #eee;
        padding: 23px 0 0;
        .faq-help-support-title {
          font-family: 'BTLight';
          font-size: 18px;
        }
        .faq-help-support-btns {
          margin-top: 15px;
          .link-btn-wrp {
            width: auto;
            vertical-align: middle;
            display: inline-block;
            margin-right: 19px;
            .btn-txt {
              display: inline-block;
              width: auto;
          }
        }
        }
  }
    .bt-col {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      margin-right: 0;
      padding-left: 9px;
      border-left: 2px solid #5514B4;
      padding-bottom: 11px;
      flex-grow: 1;
      &.bt-col-right {
        width: 48.25%;
        margin-left: 30px;
      }
      .bt-help-lnk {
        .bt-left_icon {
          display: inline-block;
          width: 26px;
          i {
            font-size: 18px;
            &.ma-fault {
              font-size: 25px;
              margin-left: -8px;
            }
            &.ma-handset {
              font-size: 20px;
              margin-left: -3px;
            }
          }
        }
        padding: 0 0 0 8px;
    line-height: 0;
    margin: 6px 0 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bt-help-lnk {
      display: block;
      vertical-align: middle;
      i {
        vertical-align: middle;
        color: #5514b4;
          font-size: 32px;
          font-weight: normal;
      }
      a {
        color: #5514b4;
        vertical-align: middle;
        width: 100%;
        .bt-lnk-txt {
          vertical-align: middle;
          padding: 5px 0 5px 7px;
          display: inline-block;
          width: calc(100% - 90px);
        }
        &:hover {
          .bt-lnk-txt {
            text-decoration: underline;
          }
        }
        .bt-icon-wrp {
          display: inline-block;
          width: 56px;
          text-align: right;
          i {
            margin-right: -12px;
            color: #5514b4;
            line-height: 0;
            top: 0;
          }
        }
      }
    }
  }
  .bt-action-btns-block {
    margin-top: 30px;
    border-top: 1px solid #EEEEEE;
    padding-top: 26px;
    .bt-col {
      width: 50%;
      display: inline-block;
      margin-right: 0;
      &.bt-col-left {
        width: 47%;
      }
      &.bt-col-right {
        margin-left: 68px;
        width: 46%;
        vertical-align: top;
      }
    }
    .bt-col-intro {
      font-size: 18px;
      font-family: "BTLight";
      display: inline-block;
      margin-bottom: 11px;
    }
  }
  .bt-title-icon-wrp {
    float: right;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: -5px;
    i {
      font-size: 40px;
      line-height: 0;
    }
  }
}

@media (max-width: 1155px) and (min-width: 1024px) {
  .bt-help-support-card {
    .bt-help-links-wrp {
      .bt-col.bt-col-right {
        width: 46%;
        margin-left: 35px;
      }
    }
    .bt-action-btns-block {
      .bt-col.bt-col-right {
        margin-left: 60px;
        width: 46%;
        vertical-align: top;
      }
    }
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .bt-help-support-card {
    .bt-help-links-wrp {
      .bt-help-links-col-wrp {
        display: block;
      }
      .bt-col {
        width: 100%;
        padding-bottom: 0;
        .bt-help-lnk {
          padding: 0 0 0 8px;
          line-height: 0;
          margin: 12px 0 20px;
      }
        &.bt-col-right {
          width: 100%;
          margin-left: 0;
          margin-top: 0;
          .bt-help-lnk {
            &:first-child {
              margin-top: 12px;
            }
          }
        }
      }
      .bt-help-lnk {
        a {
          display: block;
          .btn-txt {
            display: inline-block;
            width: calc(100% - 30px);
            padding-right: 30px;
          }
          .link-arrow {
            display: inline-block;
          }
          i {
            font-size: 23px;
            margin-top: 0;
          }
        }
      }
      .faq-help-support-wrp {
        display: block;
        margin: 25px 0 0;
        border-top: 1px solid #eee;
        padding: 20px 0 0;
    }
    }
  }
  .bt-help-support-card {
    .bt-action-btns-block {
      .bt-col {
        width: 100%;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;
        &.bt-col-left {
          width: 45%;
        }
        &.bt-col-right {
          margin-left: 67px;
          width: 45%;
          vertical-align: top;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .bt-help-support-wrp {
    padding: 0;
    margin: 0px 0 85px;
  }
  .bt-help-support-card {
    .bt-card-title-wrp {
      padding: 4px 0 10px;
      border-bottom: none;
      .bt-card-title {
        font-size: 24px;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 7px 16px;
      }
    }
    .bt-title-icon-wrp {
      float: none;
      margin-top: 15px;
      margin-right: 0;
      font-size: 22px;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      a {
        display: inline-block;
        width: 100%;
      }
    }
    .bt-help-links-wrp {
      width: 100%;
      background-color: #ffffff;
      padding: 16px;
      margin-top: 0;
      border-top: none;
      padding:16px;
      box-shadow: none;
      .bt-help-lnk {
        a {
          color: #5514b4;
          vertical-align: top;
          display: inline-block;
          vertical-align: middle;
          line-height: 1.25;
          .bt-lnk-txt {
            vertical-align: middle;
            padding: 5px 10px 5px 7px;
            display: inline-block;
            width: calc(100% - 9px);
            font-size: 14px;
          }
          .bt-icon-wrp {
            width: auto;
            i {
              display: inline-block;
              float: none;
              margin-right: -12px;
              font-size: 20px;
              vertical-align: middle;
            }
          }
          .btn-txt {
            width: calc(100% - 37px);
            padding-right: 10px;
          }
          .link-arrow {
            margin-right: -10px;
            margin-left: 7px;
        }
        }
      }
      .bt-col {
        width: 100%;
        padding-bottom: 0;
        .bt-help-lnk {
          padding: 0 0 0 8px;
          line-height: 0;
          margin: 5px 0 20px;
      }
        &.bt-col-right {
          width: 100%;
          margin-left: 0;
          margin-top: 0;
          .bt-help-lnk {
            &:first-child {
              padding-top: 10px;
            }
          }
        }
      }
      .bt-help-links-col-wrp {
        display: block;
      }
      .faq-help-support-wrp {
        margin: 25px 0 0;
        padding: 16px 0 0;
        .faq-help-support-btns{
          display: grid;
          .link-btn-wrp{
            order: 2;
          }
          button{
            order: 1;
            margin: 5px 0 20px;
          }
        }
      }
    }
    .bt-action-btns-block {
      margin-top: 23px;
      border-top: 1px solid #eeeeee;
      padding-top: 26px;
      .bt-col {
        &.bt-col-left {
          width: 100%;
        }
        &.bt-col-right {
          margin-left: 0;
          width: 100%;
          margin-top: 10px;
          border-top: 1px solid #eee;
          border-bottom: none;
          padding: 23px 0 0;
        }
      }
    }
    .link-btn-wrp {
      width: calc(100% - 26px);
  }
  }
  .bt-download-app-btn {
    display: block;
    button {
      width: 100%;
    }
  }
}
