.manage-services-wrp {
  margin-top: 60px;
}
.cardsection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

@media (max-width: 1155px) and (min-width: 1024px) {
  .manage-services-wrp {
    // display: none;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .manage-services-wrp {
    // display: none;
  }
  .cardsection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
  }
}

@media (max-width: 767px) {
  .manage-services-wrp {
    margin-top: 40px;
  }
  .cardsection {
    grid-template-columns: repeat(1, 1fr);
  }
}
