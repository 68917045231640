.account-name-update {
  width: 274px;
  position: absolute;
  right: 10px;
  top: 83px;
  .bt-label {
    font-size: 14px;
    margin: 0 0 5px;
    display: block;
    .i-btn {
      position: absolute;
      right: 0;
      top: 5px;
      cursor: pointer;
      margin: 0;
      background-color: transparent;
      padding: 0;
      border-color: transparent;
      color: #333;
    }
    .account-name-tooltip {
      display: none;
      box-shadow: -2px -2px 30px 0px #d9d9d9;
      box-sizing: border-box;
      background-color: #fff;
      padding: 12px 10px 15px;
      font-family: "BTRegular";
      width: 316px;
      font-size: 16px;
      border-radius: 4px;
      position: absolute;
      z-index: 5;
      right: 35px;
      top: -10px;
      ul {
        list-style: disc;
        margin: 0 0 20px 1.65rem;
        padding: 0;
        line-height: 1.35;
        width: calc(100% - 20px);
      }
      span {
        display: block;
        line-height: 1.25;
        font-weight: 400;
      }
      .tool-tip-right-arrow {
        width: 15px;
        height: 15px;
        background-color: #fff;
        display: block;
        position: absolute;
        right: -7px;
        top: 12px;
        box-shadow: -2px -2px 30px 0px #d9d9d9;
        transform: rotate(45deg);
      }
    }
    .i-btn {
      &:focus + .account-name-tooltip {
        display: block;
      }
    }
  }
  &.one-account {
    top: 95px;
    width: 274px;
    .bt-label {
      margin: 0;
    }
    .open-modal-btn {
      margin: 2px 0 0;
    }
  }
  .open-modal-btn {
    color: #5514b4;
    cursor: pointer;
    display: inline-block;
    margin-top: 5px;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .account-name-update {
    &.one-account {
      width: 260px;
    }
  }
}

@media (max-width: 767px) {
  .account-name-update {
    width: calc(100% - 30px);
    right: auto;
    top: 61px;
    left: 15px;
    &.one-account {
      top: 75px;
      width: auto;
      width: calc(100% - 30px);
    }
    .bt-label {
      .account-name-tooltip {
        max-width: 316px;
        width: 90%;
      }
    }
  }
}
