@charset "UTF-8";
.disableClick {
  pointer-events: none;
}
.disabled{
  pointer-events: none;
}
.progressBarGrid {
  grid-area: 2 / 1 / 3 / 6;
  -ms-grid-column: 1;
  -ms-grid-column-span:7;
  -ms-grid-row:2;
}

.verticalSpace1 {
  grid-area: 1/1/2/5;
  -ms-grid-column: 1;
  -ms-grid-column-span:7;
  -ms-grid-row:1;
  height:24px;
}

.removeProgressBar {
  display: none;
}

.stepBlock:nth-child(2) {
  .stepLabel span {
    margin-left: 5px;
  }
}

.stepBlock:nth-child(3) {
  .stepLabel span {
    margin-left: 0px;
  }
}

.stepBlock:nth-child(4) {
  .stepLabel span {
    margin-left: 1px;
  }
}

.circle {
  width: 32px;
  height: 32px;

  color: #d9d9d9;
  background: #d9d9d9;
  border-radius: 50%;

  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: center;
}

.bt-icon-tick {
  font-family: "bt-icons" !important;
  font-size: 16px;
  padding: 8px 8px 8px 8px;
}

.stepWrapper {
  display: flex;
  // margin-bottom: 20px;
  // height: 90px;
}

.stepBlock {
  width: 127px;
}
.stepBlock .circleWrapper {
  // padding: 0px 20px;
  position: relative;
  width: 91.25px;
}
.stepLabel {
  width: 70px;
  height: 40px;

  .bt-p {
    width: 70px;
    height: 40px;
    font-size: 14px;
    margin-left: 1px;
    margin-top: 16px;
  }
}

.circle.inactive {
  background: #d9d9d9;
  border: 4px solid #d9d9d9;
  pointer-events: none;
}

.circle.completed {
  color: #ffffff;
  background-color: #008A00;
  ;
}

.circle.completed:hover {
  background-color: #067702;
}

.circle.completed:active {
  background-color: #005900;
}

.circle.incomplete {
  color: #ffffff;
  border: 4px solid #1b8811;
  background-color: #ffffff;
  pointer-events: none;
}

.hidden {
  display: none;
}

.circle.incomplete::after {
  color: white;
  border: 1px solid #28a745;
  background-color: #28a745;
}
/*HORIZONTAL LINE*/
.stepBlock:not(:first-child) > .stepLabel {
  margin-left: -15px;
}

#stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
  content: "";

  width: 92.25px;

  height: 4px;
  position: absolute;
  top: 0px;

  left: 19px;
  bottom: 0px;
  text-align: center;
  background-color: #d9d9d9;
  margin: 14.5px;
  z-index: 0;
}

#stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
  content: "";

  width: 92.25px;
  height: 4px;
  position: absolute;
  top: 0px;
  bottom: 0px;

  left: 19px;
  text-align: center;
  background-color: #1b8811;
  margin: 14.5px;
  z-index: 0;
}

.stepBlock:nth-child(4) {
  .circleWrapper #circle {
    pointer-events: none;
  }
}

@media (max-width: 320px) {
  .stepBlock {
    width: 63px;
  }
  .stepBlock:nth-child(2) {
    .stepLabel span {
      margin-left: -26px;
    }
  }

  .stepBlock:nth-child(3) {
    .stepLabel span {
      margin-left: -3px;
    }
  }

  .stepBlock:nth-child(4) {
    .stepLabel span {
      margin-left: -4px;
    }
  }

  .verticalSpace1 {
    grid-area: 1/1/2/5;
    -ms-grid-column: 1;
    -ms-grid-column-span:5;
    -ms-grid-row:1;
    height:24px;
  }

  .progressBarGrid {
    grid-area: 2 / 1 / 3 / 6;
    -ms-grid-column: 1;
    -ms-grid-column-span:6;
    -ms-grid-row:2;

    .stepWrapper {
      // width:290px;
      width: 100%;
    }
    .circle.incomplete {
      color: white;
      border: 3px solid #1b8811;
      background-color: white;
      pointer-events: none;
    }
    .circle.completed {
      pointer-events: none;
    }
    .circle.inactive {
      background-color: white;

      background: #d9d9d9;
      border: 3px solid #d9d9d9;
      pointer-events: none;
    }
    .stepBlock .circleWrapper {
      // padding: 0px 20px;
      position: relative;
    }

    .bt-icon-tick {
      font-family: "bt-icons" !important;
      font-size: 12px;
      padding: 6px 6px 6px 6px;
    }
    .circle {
      width: 24px;
      height: 24px;
      pointer-events: none;
    }
    .iconTick {
      width: 11.91px;
      height: 9.52px;
    }

    #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
      //  width:60px;
      width: 60.25px;
      height: 3px;
      position: absolute;
      top: -4px !important;
      left: 11px;
    }

    #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
      content: "";
      width: 60.25px;
      height: 3px;
      position: absolute;
      top: 0px;
      left: 11px;
      bottom: 0px;
      text-align: center;
      background-color: #1b8811;
      margin: 14.5px;
      z-index: 0;
    }

    .stepBlock.selected span {
      display: block;
    }

    .stepBlock.completed span {
      display: none;
    }

    .stepBlock:first-child span {
      display: block;
    }

    .stepBlock.completed:first-child span {
      display: none;
    }

    .stepBlock.completed:last-child span {
      display: block;
    }

    .stepBlock span {
      display: none;
    }
    .hideSpan {
      display: none;
    }
    .stepLabel {
      .bt-p {
        width: 120px;
        height: 40px;
        font-size: 14px;
        margin-left: 1px;
        margin-top: 16px;
      }
    }
  }
}

@media (min-width: 321px) and (max-width: 767px) {
  .stepBlock {
    width: 87px;
  }
  .stepBlock:nth-child(2) {
    .stepLabel span {
      margin-left: -26px;
    }
  }

  .stepBlock:nth-child(3) {
    .stepLabel span {
      margin-left: -3px;
    }
  }

  .stepBlock:nth-child(4) {
    .stepLabel span {
      margin-left: -4px;
    }
  }

  .verticalSpace1 {
    grid-area: 1/1/2/5;
    -ms-grid-column: 1;
    -ms-grid-column-span:5;
    -ms-grid-row:1;
    height:24px;
  }

  .progressBarGrid {
    grid-area: 2 / 1 / 3 / 6;
    -ms-grid-column: 1;
    -ms-grid-column-span:6;
    -ms-grid-row:2;

    .stepWrapper {
      // width:290px;
      width: 100%;
    }
    .circle.incomplete {
      color: white;
      border: 3px solid #1b8811;
      background-color: white;
      pointer-events: none;
    }
    .circle.completed {
      pointer-events: none;
    }
    .circle.inactive {
      background-color: white;

      background: #d9d9d9;
      border: 3px solid #d9d9d9;
      pointer-events: none;
    }
    .stepBlock .circleWrapper {
      // padding: 0px 20px;
      position: relative;
    }

    .bt-icon-tick {
      font-family: "bt-icons" !important;
      font-size: 12px;
      padding: 6px 6px 6px 6px;
    }
    .circle {
      width: 24px;
      height: 24px;
      pointer-events: none;
    }
    .iconTick {
      width: 11.91px;
      height: 9.52px;
    }

    #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
      //  width:60px;
      width: 60.25px;
      height: 3px;
      position: absolute;
      top: -4px !important;
      left: 11px;
    }

    #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
      content: "";
      width: 60.25px;
      height: 3px;
      position: absolute;
      top: 0px;
      left: 11px;
      bottom: 0px;
      text-align: center;
      background-color: #1b8811;
      margin: 14.5px;
      z-index: 0;
    }

    .stepBlock.selected span {
      display: block;
    }

    .stepBlock.completed span {
      display: none;
    }

    .stepBlock:first-child span {
      display: block;
    }

    .stepBlock.completed:first-child span {
      display: none;
    }

    .stepBlock.completed:last-child span {
      display: block;
    }

    .stepBlock span {
      display: none;
    }
    .hideSpan {
      display: none;
    }
    .stepLabel {
      .bt-p {
        width: 120px;
        height: 40px;
        font-size: 14px;
        margin-left: 1px;
        margin-top: 16px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stepBlock {
    width: 87px;
  }
  .verticalSpace1 {
    grid-area: 1/1/2/5;
    -ms-grid-column: 1;
    -ms-grid-column-span:5;
    -ms-grid-row:1;
    height:24px;
  }
  .progressBarGrid {
    grid-area: 2 / 1 / 3 / 5;
    -ms-grid-column: 1;
    -ms-grid-column-span:5;
    -ms-grid-row:2;
  }
  .stepWrapper {
  }

  .stepBlock:nth-child(2) {
    .stepLabel span {
      margin-left: -26px;
    }
  }

  .stepBlock:nth-child(3) {
    .stepLabel span {
      margin-left: -3px;
    }
  }

  .stepBlock:nth-child(4) {
    .stepLabel span {
      margin-left: -4px;
    }
  }

  .circle.incomplete {
    color: white;
    border: 3px solid #1b8811;
    background-color: white;
    pointer-events: none;
  }
  .circle.inactive {
    background-color: white;

    background: #d9d9d9;
    border: 3px solid #d9d9d9;
    pointer-events: none;
  }
  .stepBlock .circleWrapper {
    position: relative;
  }

  .bt-icon-tick {
    font-family: "bt-icons" !important;
    font-size: 12px;
    padding: 6px 6px 6px 6px;
  }
  .circle {
    width: 24px;
    height: 24px;
  }
  .iconTick {
    width: 11.91px;
    height: 9.52px;
  }

  #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
    width: 60.25px;
    height: 3px;
    position: absolute;
    top: -4px !important;
    left: 11px;
  }

  #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
    content: "";
    width: 60.25px;
    height: 3px;
    position: absolute;
    top: 0px;
    left: 11px;
    bottom: 0px;
    text-align: center;
    background-color: #1b8811;
    margin: 14.5px;
    z-index: 0;
  }

  .stepBlock.selected span {
    display: block;
  }

  .stepBlock.completed span {
    display: none;
  }

  .stepBlock:first-child span {
    display: block;
  }

  .stepBlock.completed:first-child span {
    display: none;
  }

  .stepBlock.completed:last-child span {
    display: block;
  }
  .stepBlock span {
    display: none;
  }

  .stepLabel {
    .bt-p {
      width: 120px;
      height: 40px;
      font-size: 14px;
      margin-left: 1px;
      margin-top: 16px;
    }
  }
}
