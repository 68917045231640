@charset "UTF-8";
.body-fixed {
  overflow: auto !important;
}

.margin-left-18 {
  margin-left: 18px;
}

.cancel-accessaccount-modal-wrp {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#c4c4c4, 0.6);
  z-index: 112;
}

.enabledCancelAccountAccessModal {
  .cancel-accessaccount-modal-wrp {
    display: block;
    display: ms-block;
  }
}

.cancel-accessaccount-modal-content 
{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 150px !important;
}
.close-icon {
  position: absolute;
  right: 54px;
  font-size: 15px;
  cursor: pointer;
  color: #5514b4;
  }

  .bt-icon.bt-icon-close {
    font-family: "bt-icons" !important;
    position: absolute;
    font-size: 10px;
    margin: 0;
    padding: 0;
    border: none;
    right: -18px;
    top: 8px;
    border-radius: 0;
    }

.cancel-accessaccount-modal {
  width: 701px;
  min-height: 202px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 24px 32px 24px 24px;
}

.accessaccount-modal-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 17px;
  display: block;
  width: 90%;
  line-height: 35px;
}
.bt-p.modal-desc {
  font-size: 16px;
  display: block;
  margin-bottom: 32px;
}

.btn.primary-btn {
  background: linear-gradient(90deg, #5514b4 51.02%, #7e2ec6 100%) !important;
}

@media (min-width: 321px) and (max-width: 767px) {
  .margin-left-18 {
    margin-left: 0px;
  }
  .cancel-accessaccount-modal {
    min-height: 282px;
    width: 287px;
    padding: 24px;
    .close-icon {
      right: 40px;
    }
    .accessaccount-modal-title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 17px;
      display: block;
      width: 75%;
      line-height: 20px;
    }
    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 20px;
    }
    .cancel-accessaccount-modal-content {

 
      .buttonClass {
        display: block;
        width: 100% !important;
      }
      .btn {
        border-radius: 8px;
        &.secondary-btn {
          margin-top: 13px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .margin-left-18 {
    margin-left: 0px;
  }
  .cancel-accessaccount-modal {
    min-height: 262px;
    width: 94vw;
    padding: 24px;
    .close-icon {
      right: 43px;
    }
    .accessaccount-modal-title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 17px;
      display: block;
      width: 80%;
      line-height: 20px;
    }
    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 20px;
    }
    .cancel-accessaccount-modal-content {
      .buttonClass {
        display: block;
        
      }
      .btn {
        border-radius: 8px;
        &.secondary-btn {
          margin-top: 13px;
        }
      }
    }
  }
}
  