.technical-error-cont-grid {
  display: grid;
  grid-template-columns: repeat(12, 94px);

  grid-template-rows: 88px 40px 8px 56px 8px 72px 72px 44px 88px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;

  //IE GRID
  display: -ms-grid;
  -ms-grid-columns: 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px;
  -ms-grid-rows: 88px 40px 8px 56px 8px 72px 72px 44px 88px;

  .verticalSpace1 {
    grid-area: 1 / 1 / 2 / 7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 1;
    height: 88px;
  }
  .main-heading {
    grid-area: 2 / 1 / 3/ 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 2;
    width: 100%;
  }
  .verticalSpace2 {
    grid-area: 3 / 1 / 4/ 7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 3;
    height: 8px;
  }
  .errorText {
    grid-area: 4/ 1 / 5 / 7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 4;
  }
  .verticalSpace3 {
    grid-area: 5/ 1 / 6/ 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 5;
    height: 8px;
    width: 100%;
  }
  .noticeText {
    grid-area: 6/ 1 / 7/ 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 6;
    width: 100%;
  }
  .verticalSpace4 {
    grid-area: 7/1/8/7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 7;
    height: 72px;
  }

  .notice-txt {
    display: block;
    //  margin-bottom: 20px;
    font-size: 16px;
    color: #000000;
  }
  .action-btn-wrp {
    grid-area: 8/ 1 / 9/ 7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 8;
    .primary-btn {
      width: 140px;
    }
  }
  .removeProgressBar {
    display: none;
  }

  .verticalSpace5 {
    grid-area: 9/ 1 / 10/ 7;
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 9;
    height: 88px;
  }
}

@media (max-width: 767px) {
  .technical-error-cont-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(38px, 1fr));

    grid-template-rows: 88px minmax(40px, auto) 8px 56px 8px minmax(72px, auto) 72px 44px 88px;

    grid-column-gap: 24px;
    grid-row-gap: 0px;
    width: 94vw;

    // IE GRID
    display: -ms-grid;
    -ms-grid-columns: minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr)
      minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr);
    -ms-grid-rows: 88px minmax(40px, auto) 8px 56px 8px minmax(72px, auto) 72px
      44px 88px;

    .verticalSpace1 {
      grid-area: 1 / 1 / 2 / 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 1;
      height: 88px;
    }
    .main-heading {
      grid-area: 2 / 1 / 3/ 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 2;
    }
    .verticalSpace2 {
      grid-area: 3 / 1 / 4/ 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 3;
      height: 8px;
    }
    .errorText {
      grid-area: 4/ 1 / 5 / 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 4;
    }
    .verticalSpace3 {
      grid-area: 5/ 1 / 6/ 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 5;
      height: 8px;
    }
    .noticeText {
      grid-area: 6/ 1 / 7/ 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 6;
    }
    .verticalSpace4 {
      grid-area: 7/1/8/7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 7;
      height: 72px;
    }

    .notice-txt {
      display: block;
      //  margin-bottom: 20px;
      font-size: 16px;
      color: #000000;
    }
    .action-btn-wrp {
      grid-area: 8/ 1 / 9/ 7;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
      -ms-grid-row: 8;
      .primary-btn {
        width: 100%;
      }
      .verticalSpace5 {
        grid-area: 9/1/10/7;
        -ms-grid-column: 1;
        -ms-grid-column-span: 7;
        -ms-grid-row: 9;
        height: 88px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .technical-error-cont-grid {
    display: grid;
    grid-template-columns: repeat(12, 38px);

    grid-template-rows: 88px minmax(40px, auto) 8px 56px 8px 72px 72px 44px 88px;
    grid-column-gap: 24px;

    grid-row-gap: 0px;

    width: 94vw;

    //IE GRID
    display: -ms-grid;
    -ms-grid-columns: 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px
      38px;
    -ms-grid-rows: 88px minmax(40px, auto) 8px 56px 8px 72px 72px 44px 88px;

    .verticalSpace1 {
      grid-area: 1 / 1 / 2 / 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 1;
      height: 88px;
    }
    .main-heading {
      grid-area: 2 / 1 / 3/ 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 2;
    }
    .verticalSpace2 {
      grid-area: 3 / 1 / 4/ 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 3;
      height: 8px;
    }
    .errorText {
      grid-area: 4/ 1 / 5 / 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 4;
    }
    .verticalSpace3 {
      grid-area: 5/ 1 / 6/ 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 5;
      height: 8px;
    }
    .noticeText {
      grid-area: 6/ 1 / 7/ 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 6;
    }
    .verticalSpace4 {
      grid-area: 7/1/8/10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 7;
      height: 72px;
    }

    .notice-txt {
      display: block;
      //  margin-bottom: 20px;
      font-size: 16px;
      color: #000000;
    }
    .action-btn-wrp {
      grid-area: 8/ 1 / 9/ 10;
      width: 100%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 8;

      .primary-btn {
        width: 140px;
      }
    }
    .verticalSpace5 {
      grid-area: 9/ 1 / 10/ 10;
      -ms-grid-column: 1;
      -ms-grid-column-span: 10;
      -ms-grid-row: 9;
      height: 88px;
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .technical-error-cont {
    .notice-txt {
      margin-bottom: 20px;
    }
    .primary-btn {
      width: 100%;
      margin-top: 43px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .technical-error-cont-grid {
    margin-bottom: 212px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .technical-error-cont-grid {
    margin-bottom: 268px;
  }
}
