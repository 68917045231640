.securityCheck {
  margin-bottom: 208px;

}

@media (min-width: 768px) and (max-width: 1023px) {
  .securityCheck {
    margin-bottom: 628px;
  }
}

@media (max-width: 767px) {
  .securityCheck {
    margin-bottom: 432px;
  }
}

@media(min-width:480px) and (max-width: 767px) {
  .securityCheck {
    margin-bottom: 676px;
  }
}