.body-fixed {
  overflow: auto !important;
}
.cloudVoiceExpressButton{

width:187px;
}
.LPMoverlay {
  background: linear-gradient(90deg, #5514b4 51.02%, #7e2ec6 100%) !important;
  border-radius: 8px !important;
}
.LPMlabel {
  margin-top: -2px !important;
}

.allOtherServicesButton {
  width:164px;
}

.get-in-touch-modal-wrp {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#c4c4c4, 0.6);
  z-index: 112;
}
.enabledGetInTouchModal {
  .get-in-touch-modal-wrp {
    display: block;
    display: ms-block;
  }
}

.get-in-touch-modal {
  border-radius: 8px;
  width: 503px;
  min-height: 225px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 24px 32px 24px 24px;
  .close-icon {
    position: absolute;
    right: 40px;
    font-size: 15px;
    top: 20px;
    cursor: pointer;
    color: #5514b4;
    .bt-icon.bt-icon-close {
      position: absolute;
      font-size: 10px;
      margin: 0;
      padding: 0;
      border: none;
      right: -18px;
      top: 8px;
      border-radius: 0;
    }
  }
  .modal-title {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 17px;
    display: block;
  }
  .bt-p.modal-desc {
    font-size: 16px;
    display: block;
    margin-bottom: 16px;
  }
  .get-in-touch-modal-content {
    .btn {
      font-size: 16px;
      padding: 11px 18px;
      width: 187px;
      font-weight: normal;
      display: block;
      margin-bottom: 25px;
      &.primary-btn {
        background: linear-gradient(90deg, #5514b4 51.02%, #7e2ec6 100%);
        border-radius: 8px;
      }
      &.other-service-btn {
        width: 164px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .get-in-touch-modal {
    border-radius: 8px;
    width: 287px;
    padding: 24px;
    .modal-title {
      font-weight: 500;
      font-size: 32px;
      margin-bottom: 17px;
      display: block;
      width: 70%;
    }
    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 20px;
    }
    .get-in-touch-modal-content {
      .btn {
        width: 100%;
        margin-bottom: 12px;
        &.other-service-btn {
          width: 100%;
        }
      }
    }
  }
}
