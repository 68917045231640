body {
  &:not(.bt-reg) {
    .bt-container {
      max-width: 1174px;
      padding: 0 20px;
    }
  }
}


.progress-bar-hide {
  display: none
}

.header-hide {
  display: none;
}

.progress-bar-show {
  display: flex;
}

.progressBar-grid {
  // width:1440px;
  display: grid;

  grid-template-columns: repeat(12, 94px);
  grid-template-rows: 24px 90px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;

  //IE GRID
  display: -ms-grid;
  -ms-grid-columns: 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px;
  -ms-grid-rows: 24px 90px;

  margin-top: 160px;

}

@media (min-width: 768px) and (max-width: 1023px) {
  body {
    &:not(.bt-reg) {
      .bt-container {
        max-width: 744px;
        padding: 0 12px;
        margin: 0 auto;
      }
    }
  }

  .progressBar-grid {
    display: grid;
    grid-template-columns: repeat(12, 38px);
    grid-template-rows: 24px 60px;
    grid-column-gap: 24px;
    grid-row-gap: 0;
    max-width: 767px;
    width: 100%;
    display: -ms-grid;
    -ms-grid-columns: 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px;
    -ms-grid-rows: 24px 60px;
    margin-left: 0;

  }
}



@media (max-width: 480px) {

  body {
    &:not(.bt-reg) {
      .bt-container {
        max-width: 1174px;
        padding: 0 12px;
        margin-left: 0px;
      }
    }
  }

  .progressBar-grid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(6, 38px);
    -ms-grid-columns: minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr) minmax(38px, 1fr);
    grid-template-rows: 24px 60px;
    -ms-grid-rows: 24px 60px;

    grid-column-gap: 24px;
    grid-row-gap: 0px;
    max-width: 767px;
    width: 100%;
    margin-top: 96px;
    margin-left: 0;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  body {
    &:not(.bt-reg) {
      .bt-container {
        max-width: 744px;
        padding: 0 12px;
        margin: 0 auto;
      }
    }
  }

  .progressBar-grid {
    display: grid;
    grid-template-columns: repeat(12, 38px);
    grid-template-rows: 24px 60px;
    grid-column-gap: 24px;
    grid-row-gap: 0;
    max-width: 767px;
    width: 100%;
    display: -ms-grid;
    -ms-grid-columns: 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px 38px;
    -ms-grid-rows: 24px 60px;
    margin-left: 0;

  }
}

@media (max-width: 1023px) and (min-width: 960px) {

  .progressBar-grid {

    height: 84px;
  }

}

@media (min-width: 1024px) and (max-width: 1155px) {
  body:not(.bt-reg) .bt-container {
    max-width: 990px;
    margin-left: 50px;
  }

}