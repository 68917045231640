@charset "UTF-8";
body {
  &:not(.bt-reg) {

    [class^="bt-icon-"],
    [class*=" bt-icon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "bt-icons" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .bt-icon-close:before {
      font-family: "bt-icons" !important;
      content: "\e900";
    }

    .bt-icon-copy:before {
      content: "\e901";
    }

    .bt-icon-info:before {
      content: "\e902";
    }

    .bt-icon-info-outline:before {
      content: "\e903";
    }

    .bt-icon-link-arrow:before {
      font-family: "bt-icons" !important;
      content: "\e904";
    }

    .bt-icon-tick:before {
      font-family: "bt-icons" !important;
      content: "\e905";
    }

    .bt-icon-tick-circle-outline:before {
      font-family: "bt-icons" !important;
      content: "\e906";
    }

    .bt-icon-copy-fill:before {
      content: "\e907";
      color: #5514b4;
    }

    .bt-icon-Bar-Stroke:before {
      content: "\e909";
      color: #1b8811;
    }

    .bt-icon-Eye-Crossed:before {
      font-family: "bt-icons" !important;
      content: "\e90a";
      color: #5514b4;
      font-size: 28px;
    }

    .bt-icon-Eye:before {
      font-family: "bt-icons" !important;
      content: "\e90b";
      color: #5514b4;
      font-size: 28px;
    }

    .bt-icon-Eye-Filled:before {
      font-family: "bt-icons" !important;
      content: "\e90c";
      color: #5514b4;
      font-size: 28px;
    }

    .bt-icon-Tick-Fill:before {
      font-family: "bt-icons" !important;
      content: "\e90d";
      color: #088003;
    }

    .bt-icon-Alert-Fill:before {
      font-family: "bt-icons" !important;
      content: "\e90e";
      color: #da020f;
    }

  }
}