@font-face {
  font-family: "bt-icons";
  src: url("../fonts/bt-icons.eot?giekjv");
  src: url("../fonts/bt-icons.eot?giekjv#iefix") format("embedded-opentype"),
    url("../fonts/bt-icons.ttf?giekjv") format("truetype"),
    url("../fonts/bt-icons.woff?giekjv") format("woff"),
    url("../fonts/bt-icons.svg?giekjv#bt-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "bt-brand-icons";
  src: url("../fonts/bt-brand-icons.ttf?k08qvz") format("truetype"),
    url("../fonts/bt-brand-icons.woff?k08qvz") format("woff"),
    url("../fonts/bt-brand-icons.svg?k08qvz#bt-brand-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BTRegular";
  src: url("../fonts/newbt-webfont.eot");
  src: local("?"), url("../fonts/newbt-webfont.woff") format("woff"),
    url("../fonts/BTFont_Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BTBold";
  src: url("../fonts/BTFont_Bd.ttf");
  //src: local('?'), url('../fonts/newbtbd-webfont.woff') format('woff'), url('../fonts/newbtbd-webfont.ttf') format('truetype');
  //src: url('../fonts/newbt-webfont.eot');
  //src: local('?'), url('../fonts/newbt-webfont.woff') format('woff'), url('../fonts/BTFont_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bt";
  src: local("?"), url("../fonts/bt.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BTLight";
  src: url("../fonts/BTFont_Lt.ttf");
}

/* Start coide to add fonts for BtIcons*/
@font-face {
  font-family: "btIcon";
  src: url("../fonts/btgs-icon.eot");
  src: local("?"), url("../fonts/btgs-icon.woff") format("woff"),
    url("../fonts/btgs-icon.ttf") format("truetype"),
    url("../fonts/btgs-icon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NobbleeRegular";
  src: local("?"), url("../fonts/nobblee_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NobbleeLight";
  src: local("?"), url("../fonts/nobblee_light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Rubrik";
  src: local("?"), url("../fonts/rubrik_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RubrikSemibold";
  src: local("?"), url("../fonts/rubrik_semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
