.bt-select-box {
  height: 41px;
  position: relative;
  i {
    position: absolute;
    right: 11px;
    color: #5514b4;
    font-size: 25px;
    display: block;
    top: 10px;
    z-index: 2;
    transform: rotate(0deg);
    z-index: 2;
  }
  .selected-option {
    height: 41px;
    border: 1px solid #666;
    background-color: #fff;
    border-radius: 4px;
    padding: 9px 11px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    z-index: 2;
    &:after {
      content: "";
      width: 1px;
      display: block;
      height: 31px;
      background-color: #ddd;
      position: absolute;
      right: 40px;
      top: 5px;
    }
  }
  .select-option-list {
    border: 1px solid #ddd;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 41px;
    left: 0;
    display: none;
    .option {
      padding: 9px 11px;
      border-bottom: 1px solid #ddd;
      margin: 0;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &.bt-active {
    i {
      transform: rotate(180deg);
    }
    .select-option-list {
      display: block;
      position: absolute;
      top: auto;
      left: auto;
      max-height: 259px;
      overflow-y: auto;
      z-index: 6;
    }
  }
}
