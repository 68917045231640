.header-cont {
    display: block;
    width: 100%;
    position: absolute;
    top: 119px;
}

.header-main-heading {
    background-color: #5615b5;
    padding: 25px 0 32px;

    .header-cnt {
        display: block;
        .header-pg-title {
            font-size: 40px;
            color: #ff80ff;

            font-weight: 500;
        }
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .header-cont {
        left: 0px;
        top: 58px;
    }
}

@media (max-width: 480px) {
    .header-cont {
        top: 55px;
        left: 0px;
    }

    .header-main-heading {
        padding: 25px 0 16px;
        .header-cnt {
            .header-pg-title {
                font-size: 24px;
                color: #ff80ff;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .header-cont {
        top: 58px;
        left: 0px;
    }
    .header-main-heading {
        padding: 25px 0 16px;
        .header-cnt {
            .header-pg-title {
                font-size: 32px;
                color: #ff80ff;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1023px) and (min-width: 960px) {
    .header-cont {
        top: 98px;
        left: 0px;
        width: 100%;   
    }
}