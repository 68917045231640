.update-contact-wrp {
  background-color: #fff;
  min-height: 200px;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 7%);
  margin: 0 auto 40px;
  box-sizing: border-box;
  .form-elements {
    float: left;
  }
  .why-important-cnt {
    //width: 480px;
    border: 1px solid #ddd;
    background-color: #eee;
    min-height: 200px;
    border-radius: 10px;
    float: right;
    padding: 20px;
    i {
      vertical-align: middle;
      margin-right: 10px;
      font-size: 32px;
    }
  }
  .bt-form-element input[type="text"].valid {
    border: 5px solid black !important;
  }

  .bt-form-element input[type="text"].invalid {
    border: 1.5px solid red !important;
  }
  input[type="text"]:focus + .tooltip-holder {
    display: block;
  }
  input[type="text"]:focus + [tool-tip]:before,
  input[type="text"]:focus + [tool-tip]:after {
    display: block;
  }
  input[type="text"]:focus + [data-tip]:before,
  input[type="text"]:focus + [data-tip]:after {
    display: block;
  }
  hr.hrline {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1.5px solid #ccc;
    margin: unset;
    padding: 0;
    margin-bottom: 20px;
    &.com-pre-line {
      margin-bottom: 15px;
    }
  }
  .mainLabel {
    font-family: "BTBold";
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 10px;
  }
  .comm-pref {
    margin-top: 38px;
  }
  .bt-form-element {
    &.com-pre-el {
      .bt-label {
        margin-bottom: 18px;
      }
    }
  }
}
.form-wrapper {
  width: 486px;
  display: inline-block;
}
.why-important-cnt {
  width: 480px;
  display: inline-block;
}
.textstyle {
  // font-family: "BTRegular";
  font-family: "BTLight";
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 115% */
  color: #333333;
}
.paragraphstyle {
  font-family: "BTRegular";
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin: 5px 0 25px;
}

.none-mobile {
  display: inline-block;
}
.mobile-only {
  display: none;
}
.thankyou {
  i {
    vertical-align: middle;
  }
}
.btn-update {
  .bt-primary-btn {
    margin-left: 10px;
  }
}
.uc-actoins-btns-wrp {
  margin-top: 70px;
}
.thankyou {
  margin-top: 35px;
  margin-bottom: 75px;
  display: block;
  .link-btn-wrp {
    padding-left: 5px;
  }
}
@media (max-width: 1155px) and (min-width: 1024px) {
  .why-important-cnt {
    width: 415px;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .form-wrapper {
    width: 334px;
  }
  .why-important-cnt {
    width: 314px;
  }
}

// .page-account-intercept-updatecontact {
//   .main-nav-wrp {
//     display: none;
//   }
// }

@media (max-width: 767px) {
  .pg-title-wrp {
    margin: 13px auto 38px;
    .pg-title {
      font-size: 32px;
      line-height: 1.25;
    }
  }
  .none-mobile {
    display: none;
  }
  .mobile-only {
    display: inline-block;
  }
  .update-contact-wrp {
    display: grid;
    padding: 15px 10px 40px;
    .form-wrapper {
      width: auto;
      display: inline-block;
      order: 2;
    }
    .why-important-cnt {
      width: 100%;
      order: 1;
      padding: 10px;
      border-radius: 5px;
      min-height: auto;
      i {
        display: none;
      }
      .textstyle {
        font-size: 24px;
      }
      .cnt-wrp {
        overflow: hidden;
        max-height: 0;
        transition: all 0.35s ease-in-out;
        &.accor-expand {
          max-height: 700px;
          padding-top: 15px;
        }
        .paragraphstyle {
          &:last-child {
            margin: 0;
          }
        }
      }
      .accor-title-txt-wrp {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        .accor-title-txt {
          width: calc(100% - 40px);
          vertical-align: middle;
          display: inline-block;
        }
        i {
          vertical-align: middle;
          display: inline-block;
          font-size: 43px;
          color: #5514b4;
          margin: -2px -8px 0 2px;
          line-height: 0;
          transition: all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
        &.accor-expand {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
    .mainLabel {
      margin-top: 40px;
    }
  }
  .btn-update {
    .bt-primary-btn {
      margin-left: 0;
    }
  }
  .thankyou {
    .link-btn-wrp {
      padding-left: 0;
    }
  }
}
