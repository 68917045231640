.loaderdiv {
  width: auto;
  height: auto;
  top: unset;
  left: unset;
  position: unset;
  overflow: unset;
  // background-color: rgba(255, 255, 255, 0.7);
  // z-index: 98;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #f2f2f2;
}

.innerloaderdiv {
  display: block;
  position: absolute;
  top:unset;
  left: calc(50% - 60px);
  z-index: 99;
}

.btgs-wave-loader-animation-inner {
  span {
    transition: all 0.5s ease;
    background: #6400aa;
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 20px;
    animation: 1.4s infinite wave;
    position: absolute;
    top: 0;
    left: 20px;
    margin: auto;
  }
}

.btgs-wave-loader-animation-inner {
  span {
    &:nth-child(1) {
      position: absolute;
      top: 0;
      left: 20px;
      background-color: #6400aa;
    }
    &:nth-child(2) {
      animation-delay: 150ms;
      position: absolute;
      top: 0;
      left: 55px;
      background-color: #e60050;
    }
    &:nth-child(3) {
      position: absolute;
      top: 0;
      left: 90px;

      animation: 1.4s 0.3s infinite wavelast;
      background-color: #00a0d6;
    }
  }
}

@keyframes wave {
  0%,
  100%,
  50% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(-5px, -30px);
    width: 30px;
    height: 30px;
  }
  30% {
    transform: translate(0, 0);
    height: 20px;
    width: 20px;
  }
  40% {
    transform: translate(0, -5px);
  }
}

@keyframes wavelast {
  0%,
  100% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, -30px);
    width: 20px;
    height: 20px;
  }
  25% {
    transform: translate(-5px, -10px);
    height: 30px;
    width: 30px;
  }
  30% {
    transform: translate(-2.5px, 0);
  }
  40% {
    transform: translate(0, -5px);
    width: 20px;
    height: 20px;
  }
  50% {
    transform: translate(0, 0);
    width: 20px;
    height: 20px;
  }
}

@keyframes wavemini {
  0%,
  100%,
  50% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(-3px, -18px);
    width: 22px;
    height: 22px;
  }
  30% {
    transform: translate(0, 0);
    height: 16px;
    width: 16px;
  }
  40% {
    transform: translate(0, -3px);
  }
}

@keyframes wavelastmini {
  0%,
  100% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(0, -18px);
    width: 16px;
    height: 16px;
  }
  25% {
    transform: translate(-3px, -6px);
    height: 22px;
    width: 22px;
  }
  30% {
    transform: translate(-1.5px, 0);
  }
  40% {
    transform: translate(0, -3px);
    width: 16px;
    height: 16px;
  }
  50% {
    transform: translate(0, 0);
    width: 16px;
    height: 16px;
  }
}

@keyframes hidePreloader {
  to {
    visibility: hidden;
  }
}
