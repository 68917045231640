@charset "UTF-8";
.pos-r {
  position: relative;
}

.pg-cont {
  padding: 75px 0 0;
}

.assisted-pg-cont {
  padding: 24px 0 1px;
}

.wrap-margin-0{
margin: 0px !important;
}

// GRID DESIGN LAYOUT//

.assisted-pg-cont-grid {
  width: 1440px;
  /*  display: grid;
 // grid-template-columns: repeat(12, 94px);
   grid-template-columns: repeat(12, 94px);


  grid-template-rows: 24px 90px 36px 40px 16px 72px 24px 176px 88px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;  */

}

//End of Grid Design Layout//

/* label*/
.bt-label {
  font-size: 16px;
  color: #2a2a2a;
}

/* p*/
.bt-p {
  display: block;
}

.bt-b {
  font-weight: 600;
}

/* a*/
.bt-a {
  color: #5514b4;

  &:hover {
    text-decoration: underline;
    color: #5514b4;
  }
}

.cursor-p {
  cursor: pointer;
}

/* radio-button*/
.radio-btn {
  input.bt-radio[type="radio"] {
    accent-color: black;
    width: 34px;
    height: 34px;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    cursor: pointer;
    margin: 0;

    &:after {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background-color: #fff;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 4px solid white;
      box-shadow: 0px 0px 0px 2px #000;
    }

    &:checked:after {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: #000;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 4px solid #fff;
      box-shadow: 0px 0px 0px 2px #000;
    }
  }

  .bt-label {
    display: inline-block;
    vertical-align: middle;
  }
}

/* form element-wrp*/
.bt-form-element {
  margin: 0 0 19px;
  position: relative;

  .input-el-wrp {
    margin-top: 8px;
  }
}

/* Input-text*/
input[type="text"].bt-input-text,
input[type="text"][readonly].bt-input-text,
input[type="number"].bt-input-text,
input[type="number"][readonly].bt-input-text,
input[type="password"].bt-input-text,
input[type="password"][readonly].bt-input-text {
  height: 44px;
  width: 100%;
  text-align: left;
  border: 1px solid #4c4c4c;
  border-radius: 4px;
  padding: 9px 11px;
  box-shadow: none;
  font-family: "BT Curve";
  font-size: 16px;
  margin: 0;
  background-color: #ffffff;
  color: #2a2a2a;

  &:focus {
    box-shadow: 0 0 0.2px 3px #996cd5;
  }

  &.invalid {
    border: 1px solid #da020f;
    background-color: #ffe9ec;
    box-shadow: none;
  }
}

input[type="password"].bt-input-text,
input[type="password"][readonly].bt-input-text {
  font-family: "bt-password";
  font-size: 9px;
  padding-top: 14px;
}

input[type="password"]::-webkit-input-placeholder {
  font-family: "BT Curve";
  font-size: 16px;
  padding-top: 14px;
}

input[type="password"]::-moz-placeholder {
  font-family: "BT Curve";
  font-size: 16px;
  padding-top: 14px;
}

input[type="password"]:-ms-input-placeholder {
  font-family: "BT Curve";
  font-size: 16px;
  padding-top: 14px;
}

input[type="password"]:-moz-placeholder {
  font-family: "BT Curve";
  font-size: 16px;
  padding-top: 14px;
}

// Removed default eye icon on password fields
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

// Remove Cross X  on IE for input text boxes
input[type="text"]::-ms-reveal,
input[type="text"]::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* input error alert*/
.error-alert {
  color: #da020f;
  display: block;
  margin-top: 5px;
  line-height: 1.2;
  padding-bottom: 10px;
}

/* Button*/
.btn {
  height: auto;
  min-width: 76px;
  padding: 11px 18px;
  border-radius: 4px;
  margin: 0;
  font-size: 16px;
  font-family: "BT Curve";
  text-align: center;
  cursor: pointer;

  &.primary-btn {
    color: #ffffff;
    background: #5514b4;
    border: 1px solid #5514b4;

    &:hover {
      background-color: #4307A8;
    }

    &:active {
      background-color: #32008A;
    }
  }

  &.secondary-btn {
    color: #5514b4;
    background: #fff;
    border: 1px solid #5514b4;

    &:hover {
      border: 1px solid #7605c5;
    }
  }
}

i {
  &.bt-icon-link-arrow {
    font-family: "bt-icons" !important;
    font-size: 10px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }

  &.bt-icon-circle {
    &.bt-icon-tick {
      font-family: "bt-icons" !important;
      font-size: 7px;
      background-color: #008a00;
      padding: 3px 4px 5px;
      border-radius: 100%;
      color: #fff;
    }
  }
}

.pg-title {
  margin-bottom: 3px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .pg-cont {
    padding: 32px 0 0;
  }

  .assisted-pg-cont-grid {

    /*  display: grid;
    grid-template-columns: repeat(12, 38px);
    grid-template-rows: 20px 60px 30px 32px 16px 72px 24px 176px 88px;
    grid-column-gap: 24px;
    grid-row-gap: 0px;
   */
  }
}

@media (max-width: 767px) {
  .pg-cont {
    padding: 37px 0 20px;
  }

  .btn {
    width: 100%;
  }

  .pg-title {
    font-size: 24px;
  }

  .sub-title {
    font-size: 16px;
    line-height: 1.5;
  }
}