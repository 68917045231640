.header-cont {
  display: block;

  // width: 100vw;

  position: absolute;

  left: -1px;

  top: 119px;
}

.headerCurve {
  //height: 48px;

  position: relative;

  //overflow: hidden;

  margin-top: -1px;
  // overflow-x: hidden;

  img {
    width: 100%;

    vertical-align: top;
  }

  .img-desktop {
    display: block;
    width: 100vw;

  }

  .img-mobile {
    display: none;

  }

  .img-tablet {
    display: none;
  }
}

.header-main-heading {
  background-color: #5615b5;

  // width: 100vw;

  padding: 25px 0 32px;

  .header-cnt {
    display: block;

    .header-pg-title {
      font-size: 40px;
      color: #ff80ff;

      font-weight: 500;
    }
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .header-cont {
    left: 0px;
    top: 58px;
    // width: 100vw;

    .headerCurve {
      .img-desktop {
        display: none;

      }

      .img-mobile {
        display: none;
      }

      .img-tablet {
        display: block;
        transform: scale(1.009);
        width: 100vw;

      }
    }
  }

  .header-main-heading {
    //  width: 100vw;
  }
}

@media (max-width: 480px) {
  .header-cont {
    top: 55px;
    // left: -1px;
    left: 0px;
    // width: 100vw;

    .headerCurve {
      overflow-x: hidden;

      .img-desktop {
        display: none;
      }

      .img-mobile {
        display: block;
        transform: scale(1.009);
        width: 100vw;
      }

      .img-tablet {
        display: none;
      }
    }
  }

  .header-main-heading {
    padding: 25px 0 16px;
    //  width: 100vw;

    .header-cnt {
      .header-pg-title {
        font-size: 24px;
        color: #ff80ff;

        font-weight: 500;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .header-cont {
    top: 58px;
    // left: -1px;
    left: 0px;

    // width: 100vw;

    .headerCurve {
      .img-desktop {
        display: none;
      }

      .img-mobile {
        display: none;
      }

      .img-tablet {
        display: block;
        transform: scale(1.009);
        width: 100vw;
      }
    }
  }

  .header-main-heading {
    //   width: 100vw;
  }
}

@media (max-width: 1023px) and (min-width: 960px) {
  .header-cont {
    top: 119px;
    left: 0px;
    width: 100vw;

    .headerCurve {
      .img-desktop {
        display: none;
      }

      .img-mobile {
        display: none;
      }

      .img-tablet {
        display: block;
        transform: scale(1.009);
        width: 100vw;
      }
    }
  }

  .header-main-heading {
    //  width: 100vw;
  }
}