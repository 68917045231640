.main-nav-wrp {
  background-color: #fff;
  min-height: 61px;
  width: 100%;
  z-index: 11;
  border-bottom: 1px solid #e8e8e8;
  .main-nav {
    .main-nav-items {
      padding: 17px 0 0;
      margin: 0;
      list-style: none;
      .nav-item {
        display: inline-block;
        padding-right: 25px;
        a {
          padding: 21px 0 19px;
          color: #424242;
          position: relative;
          &:hover {
            color: #5514b4;
          }
        }
        &.bt-active {
          a {
            border-bottom: 2px solid #5514b4;
            color: #5514b4;
            font-weight: bold;
            cursor: default;
          }
        }
      }
    }
  }
  .active-nav-item-caption {
    display: none;
  }
  &:nth-child(2) {
    display: none;
  }
}

@media (max-width: 767px) {
  .main-nav-wrp {
    min-height: 48px;
    .active-nav-item-caption {
      font-weight: 600;
      padding: 13px 15px;
      min-height: 48px;
      position: relative;
      display: block;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;
      color: #5514b4;
      width: 100%;
      z-index: 2;
      background-color: #fff;
      i {
        font-size: 35px;
        position: absolute;
        right: 8px;
        top: 7px;
        color: #5514b4;
        transition: all 0.6s ease-in-out;
      }
      &.bt-active i {
        transform: rotate(180deg);
      }
    }
    .main-nav {
      &.app-container {
        width: 100%;
      }
      .main-nav-items {
        background-color: #fff;
        width: 100%;
        position: relative;
        padding: 0;
        z-index: 7;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.6s ease-in-out;
        .nav-item {
          display: block;
          padding: 0;
          border-bottom: 1px solid #e8e8e8;
          margin: 0;
          min-height: 48px;
          a {
            padding: 10px 15px 7px;
            width: 100%;
            display: inline-block;
            .nav-item-icon {
              display: inline-block;
              width: 20px;
              vertical-align: middle;
              margin: 0 15px 0 0;
              font-size: 20px;
            }
          }
          &.bt-active {
            display: none;
          }
        }
        &.bt-active {
          max-height: 100vh;
        }
      }
      .main-nav-items-wrp {
        &.bt-active {
          &:after {
            width: 100%;
            height: 100%;
            content: "";
            background: #000000b3;
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 1;
          }
        }
      }
    }
  }
}
