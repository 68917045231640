@charset "UTF-8";
.body-fixed {
  overflow: auto !important;
}

.margin-left-18 {
  margin-left: 18px;
}

.cancel-account-modal-wrp {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#c4c4c4, 0.6);
  z-index: 112;
}

.enabledCancelAccountModal {
  .cancel-account-modal-wrp {
    display: block;
    display: ms-block;
  }
}

.cancel-account-modal {
  width: 505px;
  min-height: 272px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 24px 32px 24px 24px;
  .close-icon {
    position: absolute;
    right: 54px;
    font-size: 15px;
    cursor: pointer;
    color: #5514b4;
      .bt-icon.bt-icon-close {
      font-family: "bt-icons" !important;
      position: absolute;
      font-size: 10px;
      margin: 0;
      padding: 0;
      border: none;
      right: -18px;
      top: 8px;
      border-radius: 0;
      }
    }
    i {
      &.bt-icon-link-arrow {
        font-weight: 1000;
    }
  }
  .modal-title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 17px;
    display: block;
  }
  .bt-p.modal-desc {
    font-size: 16px;
    display: block;
    margin-bottom: 32px;
  }
  .cancel-account-modal-content {
    .buttonClass {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .btn {
      padding: 10px 24px;
      gap: 10px;
      height: 44px;
      border-radius: 4px;
      &.primary-btn {
        background: linear-gradient(90deg, #5514b4 51.02%, #7e2ec6 100%);
        border-radius: 8px;
      }
      &.secondary-btn {
        background: #ffffff;
        border-radius: 8px;
      }
    }
  }
}

@media (min-width: 321px) and (max-width: 767px) {
  .margin-left-18 {
    margin-left: 0px;
  }
  .cancel-account-modal {
    width: 287px;
    padding: 24px;
    .close-icon {
      right: 40px;
    }
    .modal-title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 17px;
      display: block;
      width: 70%;
    }
    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 20px;
    }
    .cancel-account-modal-content {
      .buttonClass {
        display: block;
      }
      .btn {
        &.secondary-btn {
          margin-top: 13px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .margin-left-18 {
    margin-left: 0px;
  }
  .cancel-account-modal {
    width: 94vw;
    padding: 24px;
    .close-icon {
      right: 43px;
    }
    .modal-title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 17px;
      display: block;
      width: 70%;
    }
    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 20px;
    }
    .cancel-account-modal-content {
      .buttonClass {
        display: block;
      }
      .btn {
        &.secondary-btn {
          margin-top: 13px;
        }
      }
    }
  }
}
  