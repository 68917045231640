.manage-account-wrp {
  margin: 55px auto 0;
}
.card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}
.action-holder {
  margin: 0;
  padding: 0;
  width: auto;
}
.section-title {
  font-size: 36px;
  font-family: BTLight, calibri;
  font-weight: 300;
  margin: 0px 0px 16px;
}

.bt-card {
  width: 372px;
  min-height: 168px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.0710227);
  display: inline-block;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  }
}

.bt-card .card-header {
  padding: 30px 20px 20px 25px;
  font-family: "BTregular", calibri;
  font-size: 20px;
}
.bt-card .card-icon-wrap {
  position: absolute;
  position: absolute;
  top: 23px;
  right: 23px;
  i {
    font-size: 37px;
  }
}
.pos-r {
  position: relative;
}
.bt-card .card-title {
  color: #070707;
  font-family: "BTLight", calibri;
  font-size: 20px;
  font-weight: bold;
}

.action-holder .bt-card .card-body {
  padding: 0px 25px 25px 25px;
}

.bt-card .card-body p {
  margin: 0 0 -8px;
  padding: 0 0 0;
  min-height: 60px;
}
p {
  line-height: 21px;
  word-break: break-word;
  font-weight: 400;
  font-size: 16px;
}
.link-btn-wrp {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.link-btn-wrp i {
  vertical-align: middle;
}

@media (min-width: 1024px) and (max-width: 1175px) {
  .bt-card {
    width: 310px;
  }
  .bt-card .card-body p {
    min-height: 81px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
  }
  .bt-card {
    width: 349px;
    margin: 0 5px 15px 0;
  }
}

@media (max-width: 767px) {
  .section-title {
    font-size: 28px;
    line-height: 1.25px;
    margin-bottom: 30px;
  }
  .bt-card .card-header{
    padding: 24px 20px 20px 20px;
  }
  .action-holder {
    margin: 10px auto 0;
    padding: 0;
  }
  .card_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .bt-card {
    width: 100%;
    margin: 0 auto 20px;
    min-height: auto;
  }
  .bt-card .card-body p {
    min-height: 40px;
    margin: 0 0 18px;
  }
  .bt-card .card-icon-wrap{
    right: 20px;
    top: 18px;
  }
  .action-holder .bt-card .card-body {
    padding: 0px 50px 25px 20px;
  }
}
