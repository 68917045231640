.body-fixed {
  overflow: hidden;
}

.timeOutModalBodyButtons {
  display: flex;


}

.timeOut-modal-wrp {
  width: 100%;
  height: 160%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(#c4c4c4, 0.6);
  z-index: 111;
}

.timeOut-modal {
  background-color: #fff;
  position: fixed;
  max-width: 448px;
  top: 45%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 24px 32px 24px 24px;

  .close-icon {
    position: absolute;
    right: 40px;
    font-size: 15px;
    top: 20px;
    cursor: pointer;
    color: #5514b4;

    .bt-icon.bt-icon-close {
      position: absolute;
      font-size: 10px;
      margin: 0;
      padding: 0;
      border: none;
      right: -18px;
      top: 8px;
      border-radius: 0;
    }
  }

  .modal-title {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 24px;
    display: block;
    line-height: 36px;
    margin-right: 10px;
  }

  .timeOut-modal-content {
    width: 92%;

    .btn {
      font-size: 16px;
      padding: 11px 18px;
      font-weight: normal;
      display: block;
      margin-bottom: 10px;
    }

    .send-pin-btn {
      width: 200px;
      margin-top: 24px;
      margin-bottom: 13px;
    }

    .yes-btn {
      width: 65px;
      margin-top: 24px;
      margin-bottom: 10px;
      height: 44px;
      line-height: 20px;

    }

    .no-endNow-btn {
      width: 132px;
      margin-top: 24px;
      margin-bottom: 10px;
      margin-left: 10px;
      height: 44px;
      line-height: 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .timeOut-modal {
    width: 75%;

    .timeOut-modal-content {
      width: 90%;
    }
  }
}

@media (max-width: 767px) {
  .timeOutModalBodyButtons {
    display: block;

    .yes-btn {
      width: 100%;
    }

    .no-endNow-btn {
      width: 100%;
      margin-left: 0;

    }

  }

  .timeOut-modal-wrp {
    position: absolute;
  }

  .timeOut-modal {
    width: 320px;
    padding: 24px;

    .modal-title {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 17px;
      display: block;
      width: 90%;
      line-height: 32px;
    }

    .bt-p.modal-desc {
      line-height: 1.25;
      margin-bottom: 6px;
    }

    .timeOut-modal-content {
      width: 100%;

      .btn {
        width: 100%;
        margin-bottom: 12px;
        margin-left: 0;

        &.other-service-btn {
          width: 100%;
        }
      }
    }
  }
}