.modalpopup {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalpopup-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 764px;
  height: 272px;
  padding: 35px;
  border-radius: 5px;
  transform: translate(-50%, -20%);
  left: 50%;
  top: 20%;
  position: absolute;
  .hrline {
    &.com-pre-line {
      margin-bottom: 13px;
    }
  }
}

.headingDetail {
  font-family: "BTLight";
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}
.updatebutton {
  background-color: white;
  color: #5514b4;
  border: 1px solid #5514b4;
  border-radius: 5px;
}
.updatebuttons {
  border: 1px solid #5514b4;
  border-radius: 5px;
  margin-left: 10px;
}
.bodyDetails {
  font-family: "BTRegular";
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1.5px solid #ccc;
  // margin: 1em 0;
  padding: 0;
  margin: unset;
  margin-bottom: 40px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .modalpopup-content {
    width: 578px;
    min-height: 295px;
    padding: 45px 35px 10px;
    .updatebuttons {
      margin-left: 8px;
    }
  }
}
@media (max-width: 767px) {
  .modalpopup-content {
    width: 90%;
    height: auto;
    padding: 15px;
    .line {
      margin-bottom: 0;
    }
    .bodyDetails {
      margin: 20px 0 20px;
    }
  }
  .updatebutton {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .updatebuttons {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    margin-left: 0px;
  }
  .headingDetail {
    font-size: 22px;
    line-height: 25px;
    margin: 10px 0 10px;
  }
}
