// -------------------------------------------------------------------------------------------
// Style consists for all branding icons. Need to modify only if there are new icons introduced
// -------------------------------------------------------------------------------------------
body {
  &.bt-reg {
    .bt-icon {
      /* use !important to prevent issues with browser extensions that change fonts.
  Added BT font to allow mixing of icons and text (only intended for use as a 
  back-up method as character spacing differs from standard font) */
      font-family: "bt-brand-icons", "bt-font-regular", "Calibri" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Improve font rendering*/
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &.xs {
        font-size: 1.3em;
      }
      &.sm {
        font-size: 6em;
      }
      &.md {
        font-size: 30px;
      }
      &.lg {
        font-size: 2em;
      }
      &.xl {
        font-size: 3em;
      }
    }

    .bt-new--bt-close {
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Safari */
      transform: rotate(45deg);
      display: inline-block;
    }

    .bt-accept-call:before {
      content: "\e900";
    }
    .bt-add-contact:before {
      content: "\e901";
    }
    .bt-add-email-account:before {
      content: "\e902";
    }
    .bt-address:before {
      content: "\e903";
    }
    .bt-address-book:before {
      content: "\e904";
    }
    .bt-alarm-bell:before {
      content: "\e905";
    }
    .bt-apps:before {
      content: "\e906";
    }
    .bt-arrow-down:before {
      content: "\e907";
    }
    .bt-arrow-left:before {
      content: "\e908";
    }
    .bt-arrow-right:before {
      content: "\e909";
    }

    .bt-arrow-up:before {
      content: "\e90a";
    }
    .bt-attachment:before {
      content: "\e90b";
    }
    .bt-available:before {
      content: "\e90c";
    }
    .bt-available-credit:before {
      content: "\e90d";
    }
    .bt-backup-digital-vault:before {
      content: "\e90e";
    }
    .bt-balance-transfer:before {
      content: "\e90f";
    }
    .bt-basket:before {
      content: "\e910";
    }
    .bt-battery-empty:before {
      content: "\e911";
    }
    .bt-battery-full:before {
      content: "\e912";
    }
    .bt-battery-intermediate:before {
      content: "\e913";
    }
    .bt-battery-low:before {
      content: "\e914";
    }
    .bt-bin:before {
      content: "\e915";
    }
    .bt-bookmark:before {
      content: "\e916";
    }
    .bt-brand-heart:before {
      content: "\e917";
    }
    .bt-broadband:before {
      content: "\e918";
    }
    .bt-broadband-call:before {
      content: "\e919";
    }
    .bt-bt-logo:before {
      content: "\e91a";
    }
    .bt-bttower:before {
      content: "\e91b";
    }
    .bt-calendar:before {
      content: "\e91c";
    }
    .bt-calling:before {
      content: "\e91d";
    }
    .bt-call-messages:before {
      content: "\e91e";
    }
    .bt-call-on-hold:before {
      content: "\e91f";
    }
    .bt-call-waiting:before {
      content: "\e920";
    }
    .bt-camera:before {
      content: "\e921";
    }
    .bt-close:before {
      content: "\e922";
    }
    .bt-cloud:before {
      content: "\e923";
    }
    .bt-comment:before {
      content: "\e924";
    }
    .bt-competitions:before {
      content: "\e925";
    }
    .bt-conferencing:before {
      content: "\e926";
    }
    .bt-confirmation-message:before {
      content: "\e927";
    }
    .bt-contacts:before {
      content: "\e928";
    }
    .bt-create-message:before {
      content: "\e929";
    }
    .bt-customer-service:before {
      content: "\e92a";
    }
    .bt-data:before {
      content: "\e92b";
    }
    .bt-dialled-calls:before {
      content: "\e92c";
    }
    .bt-dial-pad:before {
      content: "\e92d";
    }
    .bt-divert-call:before {
      content: "\e92e";
    }
    .bt-document:before {
      content: "\e92f";
    }
    .bt-document-failure:before {
      content: "\e930";
    }
    .bt-document-success:before {
      content: "\e931";
    }
    .bt-downloads:before {
      content: "\e932";
    }
    .bt-email:before {
      content: "\e933";
    }
    .bt-ethernet:before {
      content: "\e934";
    }
    .bt-fail:before {
      content: "\e935";
    }
    .bt-fast-forward:before {
      content: "\e936";
    }
    .bt-favourites:before {
      content: "\e937";
    }
    .bt-fax:before {
      content: "\e938";
    }
    .bt-film:before {
      content: "\e939";
    }
    .bt-filter:before {
      content: "\e93a";
    }
    .bt-find-us:before {
      content: "\e93b";
    }
    .bt-flag:before {
      content: "\e93c";
    }
    .bt-folder:before {
      content: "\e93d";
    }
    .bt-gallery-banner-left:before {
      content: "\e93e";
    }
    .bt-gallery-banner-right:before {
      content: "\e93f";
    }
    .bt-game-chat:before {
      content: "\e940";
    }
    .bt-games:before {
      content: "\e941";
    }
    .bt-generic-alert:before {
      content: "\e942";
    }
    .bt-gift:before {
      content: "\e943";
    }
    .bt-gsm:before {
      content: "\e944";
    }
    .bt-handset:before {
      content: "\e945";
    }
    .bt-handset-settings:before {
      content: "\e946";
    }
    .bt-hearing-impaired:before {
      content: "\e947";
    }
    .bt-history:before {
      content: "\e948";
    }
    .bt-home:before {
      content: "\e949";
    }
    .bt-hotspot:before {
      content: "\e94a";
    }
    .bt-inbox:before {
      content: "\e94b";
    }
    .bt-inbox-new-message:before {
      content: "\e94c";
    }
    .bt-info:before {
      content: "\e94d";
    }
    .bt-interactive:before {
      content: "\e94e";
    }
    .bt-lan:before {
      content: "\e94f";
    }
    .bt-lan-old:before {
      content: "\e950";
    }
    .bt-laptop:before {
      content: "\e951";
    }
    .bt-line-cord-pstn:before {
      content: "\e952";
    }
    .bt-locked:before {
      content: "\e953";
    }
    .bt-markets:before {
      content: "\e954";
    }
    .bt-maximise:before {
      content: "\e955";
    }
    .bt-memory-card:before {
      content: "\e956";
    }
    .bt-menu:before {
      content: "\e957";
    }
    .bt-message-thread:before {
      content: "\e958";
    }
    .bt-microphone:before {
      content: "\e959";
    }
    .bt-minimise:before {
      content: "\e95a";
    }
    .bt-mobile:before {
      content: "\e95b";
    }
    .bt-mobile-security:before {
      content: "\e95c";
    }
    .bt-modify:before {
      content: "\e95d";
    }
    .bt-monitor:before {
      content: "\e95e";
    }
    .bt-music-ringtone:before {
      content: "\e95f";
    }
    .bt-mute-ringtone:before {
      content: "\e960";
    }
    .bt-my-vault:before {
      content: "\e961";
    }
    .bt-new:before {
      content: "\e962";
    }
    .bt-new-call:before {
      content: "\e963";
    }
    .bt-news-and-info:before {
      content: "\e964";
    }
    .bt-outbox:before {
      content: "\e965";
    }
    .bt-pause:before {
      content: "\e966";
    }
    .bt-play-roundal:before {
      content: "\e967";
    }
    .bt-play:before {
      content: "\e968";
    }
    .bt-podcasting:before {
      content: "\e969";
    }
    .bt-power:before {
      content: "\e96a";
    }
    .bt-power-cord:before {
      content: "\e96b";
    }
    .bt-presence-away:before {
      content: "\e96c";
    }
    .bt-presence-blocked:before {
      content: "\e96d";
    }
    .bt-presence-busy:before {
      content: "\e96e";
    }
    .bt-presence-offline:before {
      content: "\e96f";
    }
    .bt-print:before {
      content: "\e970";
    }
    .bt-private:before {
      content: "\e971";
    }
    .bt-record:before {
      content: "\e972";
    }
    .bt-redial:before {
      content: "\e973";
    }
    .bt-repeat-once:before {
      content: "\e974";
    }
    .bt-rewind:before {
      content: "\e975";
    }
    .bt-search:before {
      content: "\e976";
    }
    .bt-sent-items:before {
      content: "\e977";
    }
    .bt-settings:before {
      content: "\e978";
    }
    .bt-share:before {
      content: "\e979";
    }
    .bt-shuffle:before {
      content: "\e97a";
    }
    .bt-signal-strength-01:before {
      content: "\e97b";
    }
    .bt-signal-strength-02:before {
      content: "\e97c";
    }
    .bt-signal-strength-03:before {
      content: "\e97d";
    }
    .bt-sim:before {
      content: "\e97e";
    }
    .bt-skip-backward:before {
      content: "\e97f";
    }
    .bt-skip-forward:before {
      content: "\e980";
    }
    .bt-sound-level-01:before {
      content: "\e981";
    }
    .bt-sound-level-02:before {
      content: "\e982";
    }
    .bt-sound-level-03:before {
      content: "\e983";
    }
    .bt-sounds-handsfree:before {
      content: "\e984";
    }
    .bt-sounds-mute:before {
      content: "\e985";
    }
    .bt-standard-tv:before {
      content: "\e986";
    }
    .bt-stop:before {
      content: "\e987";
    }
    .bt-submit-an-order:before {
      content: "\e988";
    }
    .bt-sun:before {
      content: "\e989";
    }
    .bt-tape .path1:before {
      content: "\e98a";
      color: rgb(43, 44, 42);
    }
    .bt-tape .path2:before {
      content: "\e98b";
      margin-left: -1em;
      color: rgb(44, 43, 42);
    }
    .bt-text-message:before {
      content: "\e98c";
    }
    .bt-text-message-new:before {
      content: "\e98d";
    }
    .bt-unavailable:before {
      content: "\e98e";
    }
    .bt-undo:before {
      content: "\e98f";
    }
    .bt-unlocked:before {
      content: "\e990";
    }
    .bt-update:before {
      content: "\e991";
    }
    .bt-up-one-directory:before {
      content: "\e992";
    }
    .bt-usb:before {
      content: "\e993";
    }
    .bt-video-call:before {
      content: "\e994";
    }
    .bt-video-message:before {
      content: "\e995";
    }
    .bt-voicemail:before {
      content: "\e996";
    }
    .bt-voice-message-new:before {
      content: "\e997";
    }
    .bt-whole-home-wifi:before {
      content: "\e998";
    }
    .bt-zoom-in:before {
      content: "\e999";
    }
    .bt-zoom-out:before {
      content: "\e99a";
    }
  }
}
