@font-face {
  font-family: "MyAccount";
  src: url("../fonts//MyAccount.eot?r0fbnh");
  src: url("../fonts//MyAccount.eot?r0fbnh#iefix") format("embedded-opentype"),
    url("../fonts//MyAccount.ttf?r0fbnh") format("truetype"),
    url("../fonts//MyAccount.woff?r0fbnh") format("woff"),
    url("../fonts//MyAccount.svg?r0fbnh#MyAccount") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ma-"],
[class*=" ma-"] {
  font-family: "MyAccount" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ma-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts.
    Added BT font to allow mixing of icons and text (only intended for use as a
    back-up method as character spacing differs from standard font) */
  display: inline-block;
  font-family: "MyAccount" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Improve font rendering*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ma-icon.xs {
  font-size: 1.3em;
}

.ma-icon.sm {
  font-size: 6em;
}

.ma-icon.md {
  font-size: 30px;
}

.ma-icon.lg {
  font-size: 2em;
}

.ma-icon.xl {
  font-size: 3em;
}

.ma-apps:before {
  content: "\e900";
}
.ma-bill:before {
  content: "\e901";
}
.ma-handset:before {
  content: "\e905";
}
.ma-delivery:before {
  content: "\e902";
}
.ma-fault:before {
  content: "\e903";
}
.ma-filter:before {
  content: "\e904";
}
.ma-handset:before {
  content: "\e905";
}
.ma-hotspot:before {
  content: "\e906";
}
.ma-mail:before {
  content: "\e907";
}
.ma-messages:before {
  content: "\e908";
}
.ma-mobile:before {
  content: "\e909";
}
.ma-profile:before {
  content: "\e90a";
}
.ma-users:before {
  content: "\e90b";
}
.ma-close-white:before {
  content: "\e90c";
}
.ma-close:before {
  content: "\e90d";
}
.ma-addbill:before {
  content: "\e90e";
}
.ma-home:before {
  content: "\e90f";
}
.ma-BB:before {
  content: "\e910";
}
.ma-ABN:before {
  content: "\e911";
}
.ma-MB:before {
  content: "\e912";
}
.ma-PST:before {
  content: "\e913";
}
.ma-TV:before {
  content: "\e914";
}
.ma-add-bill:before {
  content: "\e915";
}
.ma-hub:before {
  content: "\e916";
}
.ma-add-user:before {
  content: "\e917";
}
.ma-arrow-up-fill:before {
  content: "\e918";
}
.ma-cross:before {
  content: "\e919";
}
.ma-cross-fill:before {
  content: "\e91a";
}
.ma-uniE91B:before {
  content: "\e91b";
}
.ma-uniE91C:before {
  content: "\e91c";
}
.ma-uniE91D:before {
  content: "\e91d";
}
.ma-question:before {
  content: "\e91e";
}
.ma-user-add-fill:before {
  content: "\e91f";
}
.ma-phone-fil:before {
  content: "\e920";
}
.ma-cake:before {
  content: "\e921";
}
.ma-info:before {
  content: "\e922";
}
.ma-shield:before {
  content: "\e923";
}
.ma-thumbsUp:before {
  content: "\e924";
}
.ma-btBilling:before {
  content: "\e925";
}
.ma-btBroadband:before {
  content: "\e926";
  font-weight: 600;
}
.ma-btCloud:before {
  content: "\e927";
}
.ma-btEmailUnread:before {
  content: "\e928";
}
.ma-btMobile:before {
  content: "\e929";
}
.ma-btPhone:before {
  content: "\e92a";
}
