.fixed-link-card {
  width: 274px;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 7%);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
  }
  .card-img-wrp {
    width: 100%;
    height: 172px;
    margin-top: 18px;
    .lazy-img {
      img {
        width: auto;
      }
    }
    .img-err-loaded {
      img {
        width: 100px;
      }
    }
  }
  .fix-link-btn-wrp {
    text-align: center;
    margin-bottom: 25px;
    // margin-top: 12px;
    position: relative;
    z-index: 3;
  }
  .card-link-overlay {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
  }
}

@media (max-width: 1155px) and (min-width: 1024px) {
  .fixed-link-card {
    width: 227px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .fixed-link-card {
    width: 350px;
    padding: 0 25px;
    .fix-link-btn-wrp {
      .bt-primary-btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .fixed-link-card {
    width: 100%;
    padding: 0 15px;
    .fix-link-btn-wrp {
      .bt-primary-btn {
        width: 100%;
      }
    }
    .card-img-wrp {
      width: 100%;
      height: 197px;
      margin-top: 0;
    }
  }
}
