body {
    &:not(.bt-reg) {
      .bt-container {
        max-width: 1174px;
        padding: 0 20px;
      }
    }
  }

  .selfReg {
    .progress-bar-hide {
      display: none
    }

    .header-hide {
      display: none;
    }

    .progress-bar-show {
      display: flex;
    }

    .progressBar-grid {
      // width:1440px;
      display: grid;

      grid-template-columns: repeat(12, 94px);
      grid-template-rows: 24px 90px;
      grid-column-gap: 24px;
      grid-row-gap: 0px;

      //IE GRID
      display: -ms-grid;
      -ms-grid-columns: 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px 94px;
      -ms-grid-rows: 24px 90px;

      margin-top: 160px;
    }

    .stepBlock:nth-child(2) {
      .stepLabel span {
        margin-left: 6px;
      }
    }

    .stepBlock:nth-child(3) {
      .stepLabel span {
        margin-left: -5px;
      }
    }

    .stepBlock:nth-child(4) {
      .stepLabel span {
        margin-left: 12px;
      }
    }

    .stepBlock:nth-child(5) {
      .stepLabel span {
        margin-left: 8px;
      }
    }

    .stepBlock {
      width: 136px;
    }

    .stepBlock .circleWrapper {
      position: relative;
      width: 136.25px;
    }

    #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
      width: 100.25px;
    }

    .stepLabel {
      width: 120px;
      .bt-p {
        width: 120px;
      }
    }

    .stepBlock:not(:first-child) > .stepLabel {
      margin-left: -30px;
      .bt-p {
        width: 120px;
      }
    }

    #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
      content: "";
      width: 100.25px;
    }

    .error-alert {
      padding-bottom: 0px;
    }

    .error-text {
      font-size: 15px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    body {
      &:not(.bt-reg) {
        .bt-container {
          max-width: 744px;
          padding: 0 12px;
          margin: 0 auto;
        }
      }
    }

    .selfReg {
      .stepBlock {
        width: 87px;
      }

      .progressBar-grid {
      grid-template-columns: repeat(12, 38px);
      margin-top: 170px;
    }

      .stepBlock .circleWrapper {
        width: 60px;
      }

      .progressBarGrid #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
        content: "";
        width: 60px;
      }

      .progressBarGrid #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
        width: 60px;
      }
    }
  }

  @media (max-width: 320px) {
    body {
      &:not(.bt-reg) {
        .bt-container {
          max-width: 1174px;
          padding: 0 12px;
          margin-left: 0px;
        }
      }
    }

    .selfReg {
      .progressBar-grid {
        grid-template-columns: repeat(6, minmax(38px, 1fr));
        margin-top: 96px;
      }

      .progressBarGrid {
        margin-top: 40px;
      }

      .stepBlock {
        width: 63px;
      }

      .stepBlock .circleWrapper {
        width: 35.25px;
      }

      .progressBarGrid #stepWrapper .stepBlock span {
        margin-left: 0px;
      }

      .progressBarGrid #stepWrapper .stepBlock:nth-child(4) span {
        margin-left: 12px;
      }

      .progressBarGrid #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
        content: "";
        width: 35.25px;
      }

      .progressBarGrid #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
        width: 35.25px;
      }
    }
  }
  
  @media (min-width: 321px) and (max-width: 767px) {
    body {
      &:not(.bt-reg) {
        .bt-container {
          max-width: 744px;
          padding: 0 12px;
          margin: 0 auto;
        }
      }
    }

    .selfReg {
      .progressBar-grid {
        grid-template-columns: repeat(6, minmax(38px, 1fr));
        margin-top: 96px;
      }

      .progressBarGrid {
        margin-top: 40px;
      }

      .progressBarGrid #stepWrapper .stepBlock span {
        margin-left: 0px;
      }

      .stepBlock {
        width: 63px;
      }

      .stepBlock .circleWrapper {
        width: 35.25px;
      }

      .progressBarGrid #stepWrapper .stepBlock:not(:last-child) > .circleWrapper::after {
        content: "";
        width: 35.25px;
      }

      .progressBarGrid #stepWrapper .stepBlock:nth-child(4) span {
        margin-left: 12px;
      }

      .progressBarGrid #stepWrapper .stepBlock.completed:not(:last-child) > .circleWrapper::after {
        width: 35.25px;
      }
    }
  }